<template lang="pug">
  .wrapper
    main
      .register(v-if="!signed")
        .register__wrapper
          a.register__logo(@click="goRoot")
            include ../../assets/images/logo_title.svg
          .register__form
            button.register__form-close(@click="goRoot")

            .register__form-tabs
              router-link(
                :to="{ name: 'SignUp' }"
                v-slot="{ isExactActive }")
                button.register__form-tabs-button(type="button" @click="goSignUp" :class="isExactActive ? 'active' : ''") РЕГИСТРАЦИЯ

              router-link(
                :to="{ name: 'SignIn' }"
                v-slot="{ isExactActive }")
                button.register__form-tabs-button(type="button" @click="goSignIn" :class="isExactActive ? 'active' : ''") ВХОД
            ValidationObserver(ref="form")
              form.register__form-item(
                @submit.prevent="signIn"
                role="form")
                label.register__form-label
                  span.register__form-label-title Ваш емайл
                ValidationProvider(
                  mode="lazy"
                  name="email"
                  rules="requiredEmail|email" )
                  div(slot-scope="{ errors, validated, invalid }")
                    input(
                      :class="validated && invalid ? 'input-error' : ''"
                      name="email"
                      v-model="user.email")
                    div.styled-errors(
                      role="alert"
                      v-if="validated && invalid")
                      p.errors-text {{ errors[0] }}
                label.register__form-label
                  span.register__form-label-title Ваш пароль
                ValidationProvider(
                  mode="lazy"
                  name="password"
                  rules="requiredPassword")
                  div(slot-scope="{ errors, validated, invalid }")
                    input(
                      :class="validated && invalid ? 'input-error' : ''"
                      name="password"
                      type="password"
                      v-model="user.password")
                    div.styled-errors(
                      role="alert"
                      v-if="validated && invalid")
                      p.errors-text {{ errors[0] }}

                a.recovery-link(@click="goPasswordRecovery" style="cursor: pointer;") Забыли пароль?
                p.email-error(v-if="invalidCredentials")
                  br
                  | Вход не выполнен, проверьте правильность введенных данных

                button.register__form-submit.button.button_smaller.button_green(
                  type="submit" style="cursor: pointer;") Войти
            //.social
              .logos-header Войти через соцсеть:
              a.logos.fb-logo(@click="signOmniAuth('facebook')")
              a.logos.vk-logo(@click="signOmniAuth('vkontakte')")
              a.logos.od-logo(@click="signOmniAuth('odnoklassniki')")
</template>

<script>
import authMixin from 'mixins/auth'
import navigationMixin from 'mixins/navigation'

export default {
  name: 'SignIn',
  mixins: [authMixin, navigationMixin],
  data () {
    return {
      user: {
        email: null,
        password: null
      },
      invalidCredentials: false
    }
  },
  methods: {
    signIn () {
      this.$refs.form.validate()
        .then(success => {
          if (success) this.signInRequest()
        })
    },
    signOmniAuth (method) {
      this.$api.signInOmniAuth(method)
    },
    signInRequest () {
      const params = { email: this.user.email, password: this.user.password }
      this.$api.signIn(params)
        .then(({ headers }) => {
          const credentials = {
            accessToken: headers['access-token'],
            client: headers.client,
            expiry: headers.expiry,
            tokenType: headers['token-type'],
            uid: headers.uid
          }
          this.cookies.set(process.env.VUE_APP_COOKIE_CRED_NAME, JSON.stringify(credentials),
            {
              domain: process.env.VUE_APP_COOKIE_TLD,
              path: '/',
              sameSite: 'lax'
            })
          window.location = process.env.VUE_APP_DOCS_URL
        })
        .catch(() => (this.invalidCredentials = true))
    }
  }
}
</script>
